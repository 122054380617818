/*------------------------------------*\
 #GO-TO-HOMEPAGE
\*------------------------------------*/


// Expand ‘Go to Homepage’ button when scrolled to page bottom
var home_link = document.getElementById("go-to-homepage");

if ( home_link !== null ) {

  window.onscroll = function(ev) {
    
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      home_link.classList.add("page-bottom");
    } else {
      home_link.classList.remove("page-bottom");
    }

  }

}
